@import 'styles/main.scss';

.wrapper {
  padding: 20px 20px;
  border-radius: 36px;
  box-shadow: var(--card-shadow);
  cursor: pointer;
  transition: $transition;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  -webkit-tap-highlight-color: transparent;
  background-color: $white;
  color: var(--text);
  height: 330px;

  @media screen and (max-width: 1200px) {
    padding: 20px 18px;
  }

  @media screen and (min-width: $xs-up) {
    height: 370px;
  }

  @media screen and (min-width: $xs-up) {
    &:hover {
      box-shadow: var(--card-shadow-hover);

      .read {
        opacity: 1;
      }

      .title {
        color: var(--accent);
      }
    }
  }
}

.link {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  outline: 0;
  -webkit-tap-highlight-color: transparent;
  color: inherit;

  a {
    display: block;
    width: 100%;
    height: 100%;
    outline: 0;
    -webkit-tap-highlight-color: transparent;
  }
}

.hit {
  overflow: visible;
  background: var(--gradient-3);
  color: $white;
  transition: all 0.3s ease-in-out;

  .price__tips {
    color: #ebdfdb;
  }

  span.price__discount {
    color: #ebdfdb;

    &::before {
      border-bottom: 2px solid $white;
    }
  }

  .button {
    color: var(--accent);
    background: var(--accent-light);
  }

  @media screen and (min-width: $xs-up) {
    &:hover {
      opacity: 0.8;

      .price,
      .text,
      .title,
      .readText {
        color: $white;
      }
    }
  }
}

.head {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
}

.tags {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;

  .free {
    background-color: var(--ui-02-main);
    color: $white;
  }

  .new {
    background-color: var(--ui-03-main);
    color: $white;
  }

  .hit-tag {
    background-color: $orange;
    color: $white;
    display: flex;
    gap: 4px;
  }
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.title {
  @include font-22-bold;
  margin-bottom: 6px;
  transition: $transition;

  @media screen and (max-width: 1024px) {
    @include font-20-bold;
  }
}

.text {
  @include font-14;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin-bottom: 6px;
}

.price {
  display: flex;
  flex-direction: column;
  @include font-12-bold;

  &__tips {
    color: var(--text-light);
  }

  &__wrapper {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    margin-bottom: 12px;
  }

  &__currency {
    @include font-22-bold;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 4px 12px;

    &--small {
      margin-bottom: 5px;
      @include font-22-bold;

      @media screen and (max-width: 1024px) {
        @include font-20-bold;
      }
    }
  }

  &__discount {
    position: relative;
    padding-left: 6px;
    @include font-14-bold;
    color: var(--text-light);

    &::before {
      content: '';
      position: absolute;
      bottom: 6px;
      width: 94%;
      height: 50%;
      border-bottom: 2px solid var(--text-light);
      transform-origin: left bottom;
      transform: rotate(-6deg);
    }

    &--small {
      @include font-14-bold;
    }
  }
}

.price-free {
  @include font-22-bold;
  margin-bottom: 12px;
}

.button {
  width: 100%;
  height: 48px;
  padding: 9px 32px;
  @include font-16-bold;
  white-space: nowrap;

  &.not-in-cart {
    white-space: nowrap;
  }

  @media screen and (max-width: 1300px) {
    @include font-14-bold;
  }

  @media screen and (max-width: 1024px) {
    font-size: initial;
    height: 40px;
  }
}

.mobile-buttons {
  display: flex;
  gap: 8px;
  position: relative;
  // z-index: 1;

  .mobile-button {
    height: 100%;
    width: 48px;
    svg {
      fill: var(--accent);
    }

    @media screen and (max-width: 1024px) {
      height: 40px;
      width: 40px;
    }
  }
}

.small {
  width: 100%;
  min-height: 298px;
  height: 100%;

  &:hover {
    box-shadow: var(--card-shadow-hover);
  }

  .title {
    @include font-20-bold;
    text-overflow: unset;
  }

  .price {
    margin-bottom: 8px;
    font-size: 12px;
    transition: all 0.3s ease-in-out;
  }

  span.discount {
    @include font-14-bold;
    color: #fff;
    padding-left: 6px;

    &::before {
      content: '';
      position: absolute;
      left: 10;
      bottom: 8px;
      width: 94%;
      height: 50%;
      border-bottom: 2px solid #fff;
      transform-origin: left bottom;
      transform: rotate(-6deg);
    }
  }

  :global(.common__tooltip) {
    min-width: 200px !important;
  }

  :global(.common__tooltip-inner) {
    font-size: 12px;
  }
}

.modal {
  position: relative;
  display: flex;
  min-height: 356px;

  @media screen and (max-width: 1024px) {
    flex-direction: column;
    padding-bottom: 80px;
  }

  .left {
    padding: 40px;
    width: 472px;
    color: var(--text);

    @media screen and (max-width: 1024px) {
      width: 100%;
      padding: 0;
    }

    .cart-title {
      margin-bottom: 8px;
      @include font-22-bold;
    }

    .text {
      margin-bottom: 32px;
      @include font-14;
      transition: all 0.3s ease-in-out;
    }

    .label {
      margin-bottom: 8px;
      margin-top: 32px;
      @include font-12-bold;
    }

    .add {
      margin-top: 16px;
      margin-bottom: 40px;
    }

    .sources {
      width: 100%;
      // overflow: auto;
      display: flex;
      flex-direction: column;
      gap: 8px;

      @media screen and (max-width: 1024px) {
        max-height: none;
      }

      .source {
        input {
          height: 40px;
        }
      }

      &::-webkit-scrollbar {
        display: none;
      }

      .source-wrapper {
        display: flex;
        width: 100%;
        gap: 8px;

        .source {
          width: 344px;

          @media screen and (max-width: 1024px) {
            max-width: 344px;
            width: auto;
          }
        }

        .delete {
          width: 40px;
          height: 40px;
          border: 1px solid #e4e4e5;
          border-radius: 8px;
          padding: 0;

          svg {
            width: 20px;
            height: 20px;
          }
        }
      }
    }
  }

  .buttons {
    display: flex;
    height: 40px;
    gap: 8px;

    @media screen and (max-width: 1024px) {
      flex-direction: column-reverse;
      height: auto;
      margin-top: 24px;
    }

    .button {
      @media screen and (max-width: 1024px) {
        height: 40px;
      }
    }
  }

  .right {
    position: relative;
    width: 288px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: var(--text);
    border-radius: 0 36px 36px 0;

    @media screen and (max-width: 1024px) {
      width: 100%;
    }

    .bg-image {
      object-fit: fill;
      /* height: 410px; */
      width: 100%;
      position: absolute;
      height: 100%;
      top: 0;
      left: 0;

      @media screen and (max-width: 1024px) {
        display: none;
      }
    }

    .price-count {
      position: relative;
      box-shadow:
        0px 15px 125px rgba(201, 180, 250, 0.13),
        0px 4.52206px 37.6838px rgba(201, 180, 250, 0.0847066),
        0px 1.87823px 15.6519px rgba(201, 180, 250, 0.065),
        0px 0.67932px 5.661px rgba(201, 180, 250, 0.0452935);
      border-radius: 16px;
      background-color: $white;
      padding: 17px 16px;
      width: 224px;

      @media screen and (max-width: 1024px) {
        position: relative;
        background-color: var(--accent-light);
        box-shadow: none;
        width: 100%;
        left: auto;
      }

      .info {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        width: 100%;
        align-items: baseline;
        @include font-12;

        span {
          @include font-14-bold;
        }
      }

      .total {
        margin-top: 13px;
        padding-top: 12px;
        border-top: 1px solid rgba(var(--text-light), 0.3);
        @include font-14;

        span {
          @include font-22-bold;
        }
      }
    }

    .hint {
      color: var(--text-light);
      margin-top: 12px;
      @include font-12;
    }
  }
}

.read {
  // background-color: var(--accent-light);
  // padding: 4px 12px;
  border-radius: 36px;
}

.readIcon {
  opacity: 1;
}

.in-cart {
  display: flex;
  align-items: center;
  background: var(--accent-light);
  color: var(--accent);
  padding: 13px 8px;
  &__btn {
    display: flex;
    justify-content: center;
    align-items: center;
    &:first-child {
      margin-right: auto;
    }
    &:last-child {
      margin-left: auto;
    }
    svg {
      stroke: var(--accent);
    }
  }

  .count {
    margin-left: 8px;
    background: var(--accent);
    border-radius: 50%;
    color: $white;
    height: 16px;
    min-width: 16px;
    width: auto;
    width: fit-content;
    @include font-12-bold;

    span {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      top: 1px;
    }
  }
}

.tag {
  text-wrap: nowrap;
  @include font-11-bold;
  padding: 4px 12px;
}

.read {
  display: flex;
  align-items: center;
  margin-top: 8px;
  opacity: 0;
  transition: $transition;
  max-width: max-content;
  z-index: -1;

  @media screen and (max-width: $xs-up) {
    opacity: 1;
    background-color: var(--accent-light);
    padding: 4px 12px;
    border-radius: 36px;
  }
}

.readText {
  color: var(--accent);
  @include font-16-bold;

  @media screen and (max-width: $xs-up) {
    @include font-12-bold;
  }
}

.readIcon {
  margin-left: 10px;
  opacity: 0;

  @media screen and (max-width: $xs-up) {
    opacity: 1;
  }
}

.tooltipWrap {
  position: relative;
  display: flex;
  align-items: center;

  .tooltip {
    min-width: 250px;
    z-index: 1;
    white-space: normal;
    color: var(--text);
    display: block;
    background-color: #fff;
    font-size: 14px;
    line-height: 140%;
    box-shadow:
      0px 0px 8px rgb(0 0 0 / 10%),
      0px 4px 8px rgb(0 0 0 / 10%);
    border-radius: 8px;
    transition: 0.3s;
    width: 100%;
    text-align: center;

    * {
      white-space: normal;
    }

    :global(.common__tooltip-inner) {
      box-shadow: none;
      height: unset;
      background: transparent;
      display: block;
    }

    :global(.common__tooltip-text) {
      width: 100%;
      display: block;
    }

    @media screen and (max-width: $xs-up) {
      min-width: 200px;
    }
  }

  @media screen and (max-width: $xs-up) {
    margin-top: -6px;
  }
}

.tooltipContainer {
  position: relative;
  top: -2px;
  margin-left: 6px;
}

.closeTimeTooltip {
  display: flex;
  align-items: center;
  width: max-content;
  position: relative;
}

.closeTimeInner {
  display: flex;
  color: $white;
  @include font-12;
  padding-right: 5px;
  margin-top: 6px;
  transition: $transition;
}

.closeTime {
  font-weight: 500;
  padding-left: 3px;
}

.counter {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
  padding: 2px 4px;
  margin: 0 0 0 8px;
  border-radius: 50%;
  @include font-12-bold;
  line-height: 1;
  background: var(--accent);
  color: $white;
}

.counterMedium {
  width: 20px;
  height: 20px;
}

.textAddBtn {
  @include font-14-bold;
}

.price__wrapper--discount {
  flex-direction: column;

  .price__discount {
    padding-left: 0;
  }
}
